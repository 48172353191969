import React from "react";
import { AiOutlineHome, AiOutlineUser } from 'react-icons/ai'; // Ícone para Home e Perfil
import { FaWhatsapp } from 'react-icons/fa'; 
import { FaFacebook } from "react-icons/fa";
import { MdOutlineMiscellaneousServices } from "react-icons/md";

function Footer() {
  // Mensagem inicial
  const initialMessage = encodeURIComponent("Olá! Pode me ajudar?");
  
  return (
    <div className="fixed inset-x-0 bottom-0 bg-gray-800 text-white flex justify-around items-center h-16">
      <a href="/" className="flex flex-col items-center justify-center">
        <AiOutlineHome size={24} />
        <span>Home</span>
      </a>
      <a href="https://www.facebook.com/associacaobangu" target="_blank" className="flex flex-col items-center justify-center">
        <FaFacebook size={24} />
        <span>Facebook</span>
      </a>
      <a href={`https://api.whatsapp.com/send?phone=5521984858160&text=${initialMessage}`} target="_blank" rel="noopener noreferrer" className="flex flex-col items-center justify-center">
        <FaWhatsapp size={24} />
        <span>WhatsApp</span>
      </a>
      <a href="/perfil" className="flex flex-col items-center justify-center">
        <AiOutlineUser size={24} />
        <span>Perfil</span>
      </a>
    </div>
  );
}

export default Footer;
