import React, { useState, useEffect } from "react";
import logo from '../assets/logo_ltB.png';
import { FaThermometerHalf } from 'react-icons/fa';

function Header() {
  const [showWarningBar, setShowWarningBar] = useState(true);
  const [dateInfo, setDateInfo] = useState({ weekday: '', monthYear: '', day: '' });
  const [weather, setWeather] = useState({ city: '', temperature: '', icon: '' });

  useEffect(() => {
    const updateDateInfo = () => {
      const now = new Date();
      const weekday = now.toLocaleDateString('pt-PT', { weekday: 'long' });
      const monthYear = now.toLocaleDateString('pt-PT', { month: 'long', year: 'numeric' });
      const day = now.toLocaleDateString('pt-PT', { day: '2-digit' });

      setDateInfo({
        weekday,
        monthYear,
        day,
      });
    };

    updateDateInfo(); // Atualiza imediatamente ao montar
    const intervalId = setInterval(updateDateInfo, 60 * 1000); // Atualiza a cada minuto

    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowWarningBar(false);
      } else {
        setShowWarningBar(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Obter localização do usuário e buscar dados meteorológicos
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        fetchWeather(position.coords.latitude, position.coords.longitude);
      });
    } else {
      console.error("Geolocation is not supported by this browser.");
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearInterval(intervalId); // Limpa o intervalo quando o componente é desmontado
    };
  }, []);

  const fetchWeather = async (lat, lon) => {
    const apiKey = "b672b5882954bc165bccce41ae1e1da5"; // Use sua própria chave API
    const url = `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${apiKey}&units=metric&lang=pt`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      setWeather({
        city: data.name,
        temperature: Math.round(data.main.temp),
        icon: data.weather[0].icon,
      });
    } catch (error) {
      console.error("Failed to fetch weather data:", error);
    }
  };

  return (
    <>
      {showWarningBar && (
        <div className="bg-blue-400 text-white text-center fixed top-0 w-full z-20 h-6">
          Site em construção
        </div>
      )}

<nav className={`bg-blue-900 text-white p-4 fixed top-0 w-full z-10 h-30 ${showWarningBar ? 'mt-6' : 'mt-0'}`}>
  <div className="container mx-auto flex justify-between items-center">
    
      <img src={logo} alt="Logo ACERB" className="w-auto h-20 ml-2" />
    
    <div className="flex items-center">
      
      <div className="flex flex-col mr-1">
        <span className="font-bold text-sm text-right">{dateInfo.weekday}</span>
        <span className="text-xs text-right">{dateInfo.monthYear}</span>
      </div>
      <div className="text-4xl font-bold mr-6 text-left">{dateInfo.day}</div> 

      <div className="flex flex-col text-center mr-2">
        {weather.icon && (
          <img src={`http://openweathermap.org/img/wn/${weather.icon}.png`} alt="Weather Icon" className="mb-0" />
        )}
        {weather.city && (
          <div className="flex flex-col text-center mr-2">
            <span className="font-bold text-lg mb-0">{weather.temperature}°C</span> 
            <span className="text-sm mt-0">{weather.city}</span> 
          </div>
        )}
      </div>
    </div>
  </div>
</nav>


    </>
  );
}

export default Header;
