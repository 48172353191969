import React from "react";

function Homepage() {
  return (
      
    <div className="flex flex-col items-center bg-gray-900 text-white pt-40 pb-16">
    
    <div className="overflow-auto max-h-screen w-full px-4">
      
      <div className="space-y-4">
        
        <div className="pt-6 px-2">
          <p className="text-sm">Atalhos</p>
          <hr className="my-2" />
        </div>

      <div className="w-full px-2 flex justify-center space-x-4">
       
        <div className="flex flex-col space-y-2 w-1/2">
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full">Associe-se</button>
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full">História</button>
        </div>

       
        <div className="flex flex-col space-y-2 w-1/2">
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full">Serviços</button>
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full">Contatos</button>
        </div>
      </div>

      <div className="w-full px-2 pt-6">
        <p className="text-sm">Últimas Notícias</p>
        <hr className="my-2" />
      </div>

      <div className="rounded bg-gray-800 px-4 mx-2 p-5">
        <p className="text-xs">Não tem notícias</p>
      </div>

      <div className="w-full px-2 pt-6 ">
        <p className="text-sm">Eventos</p>
        <hr className="my-2" />
      </div>

      <div className="rounded bg-gray-800 m-2 px-4 p-5">
        <p className="text-xs">Não tem eventos</p>
      </div>

      <div className="m-2 p-4 bg-gray-700 rounded ">
        <p className="text-sm">Av. Santa Cruz, 4425 - Salas 201 | 202 | 203 - Bangu - Rio de Janeiro - RJ. <br />Tel.: (21) 3331-2127</p>
      </div>
    </div>
    </div>
    </div>
  );
}

export default Homepage;
