
import Footer from './components/Footer';
import Header from './components/Header';
import Homepage from './pages/Homepage';
import { Analytics } from "@vercel/analytics/react";

function App() {
  return (
    <>
      <Header />
      <Homepage />
      
      <Footer />
    </>
  );
}

export default App;
